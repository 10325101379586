import * as React from 'react';
import { Liquid } from 'liquidjs';
import sendError from '@web/utils/sendError';
import { formatPhoneNumber } from '@admin/utils/formatPhoneNumber';

const engine = new Liquid();

const defaultValues = {
  campaign: {
    description: '{{campaign.description}}',
    join_code: '{{campaign.join_code}}',
    name: '{{campaign.name}}',
  },
  email: '{{email}}',
  first_name: '{{first_name}}',
  full_name: '{{full_name}}',
  last_name: '{{last_name}}',
  phone: '{{phone}}',
  state_abbrev: '{{state_abbrev}}',
  user: {
    email: '{{user.email}}',
    first_name: '{{user.first_name}}',
    fullname: '{{user.fullname}}',
    last_name: '{{user.last_name}}',
    phone: '{{user.phone}}',
  },
};

function useInterpolation(str, connectedContact) {
  const [interpolated, setInterpolated] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    async function interpolate() {
      try {
        // Create a copy of defaultValues and merge connectedContact
        const allValues = { ...defaultValues, ...formatPhoneFields(connectedContact) };

        // Find all placeholders in the format {{variable}}
        const placeholders = str.match(/\{\{(.*?)\}\}/g) || [];

        // Add missing variables to allValues to preview them until contact is added
        placeholders.forEach(placeholder => {
          const key = placeholder.replace(/\{\{|\}\}/g, '').trim();
          if (!(key in allValues)) {
            allValues[key] = placeholder; // Assign placeholder to the missing key
          }
        });

        const result = await engine.parseAndRender(str, allValues);
        setInterpolated(result);
        setLoading(false);
      } catch (err) {
        sendError('Error attempting to interpolate interactive script', err);
        // In case of an error, just use the initial string to prevent the odd empty string confusing customers
        setInterpolated(str);
        setLoading(false);
      }
    }
    interpolate();
  }, [connectedContact, str]);

  return { interpolated, interpolationLoading: loading };
}

export const formatPhoneFields = obj => {
  const newObj = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      key.toLowerCase().includes('phone') &&
      typeof obj[key] === 'string' &&
      obj[key] !== `{{${key}}}`
    ) {
      newObj[key] = formatPhoneNumber(obj[key]);
    } else if (obj[key] && typeof obj[key] === 'object') {
      newObj[key] = formatPhoneFields(obj[key]); // Recursive call for nested objects
    } else {
      newObj[key] = obj[key]; // Copy other properties as-is
    }
  }
  return newObj;
};
export default useInterpolation;
