import * as React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import sendError from '@web/utils/sendError';
import styled from 'styled-components';
import { setCallData, setVolunteerPhone, volunteerConnected } from '@web/reducers/dialerReducer';

import { AuthContext } from '@web/utils/context';
import BackButton from '../../components/BackButton';
import { Body, Title } from '@web/components/DialerActivity/components/TextStyles';
import { message } from 'antd';
import NextButton from '../../components/NextButton';
import Page, { CopyWrapper, PageCopy } from '../../components/Page';
import PhoneInput from './PhoneInput';
import PhoneNumberIcon from '@web/components/DialerActivity/icons/PhoneNumber';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const InputContainer = styled.div`
  margin: 0 auto;
  max-width: 227px;
`;

function EnterPhoneNumber({ activity, body, onBack, onNext, title }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { volunteerPhone } = useSelector(state => state.dialer);
  const [loading, setLoading] = React.useState(false);
  const { user } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (!volunteerPhone) {
      handleSetPhone(user?.phone || '');
    }
    // eslint-disable-next-line
  }, []);

  function handleSetPhone(phone) {
    dispatch(setVolunteerPhone(removeStart(phone)));
  }

  function removeStart(p) {
    if (!p) {
      return null;
    }
    if (p.slice(0, 2) === '+1') {
      return p.slice(2);
    }
    return p;
  }

  function callUser() {
    setLoading(true);
    const url = `/api/v2/activities/${activity.id}/volunteer_voice_call`;
    // Remove mask and add country code to avoid API errors
    const phone = `+1${volunteerPhone.replace(/\D/g, '')}`;

    axios
      .patch(url, { phone })
      .then(({ data }) => {
        dispatch(setCallData(data));
        dispatch(volunteerConnected());
        onNext();
      })
      .catch(err => {
        sendError('Error calling user', {
          request: err?.request,
          response: err?.response,
        });
        message.error("We're unable to make a call at this time. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Page>
      <PhoneNumberIcon size={118} />
      <PageCopy>
        <CopyWrapper>
          <Title>{title || t('activity.phone_bank.enter_phone')}</Title>
          <Body>{body || t('activity.phone_bank.enter_phone_description')}</Body>
        </CopyWrapper>
        <InputContainer>
          <PhoneInput
            data-testid="phone-input"
            phoneNumber={volunteerPhone}
            setPhoneNumber={handleSetPhone}
          />
        </InputContainer>
      </PageCopy>
      <NextButton
        data-testid="call-me-button"
        disabled={loading || !volunteerPhone.length}
        loading={loading}
        onClick={callUser}
      >
        {t('activity.phone_bank.call_me')}
      </NextButton>
      {onBack && <BackButton onClick={onBack} />}
    </Page>
  );
}

EnterPhoneNumber.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  body: PropTypes.string,
  onBack: PropTypes.func,
  onNext: PropTypes.func.isRequired,
  title: PropTypes.string,
};

EnterPhoneNumber.defaultProps = {
  body: null,
  onBack: null,
  title: null,
};

export default EnterPhoneNumber;
