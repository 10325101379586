import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Body, Title } from '@web/components/DialerActivity/components/TextStyles';
import BackButton from '../../components/BackButton';
import NextButton from '../../components/NextButton';
import Page, { CopyWrapper, PageCopy } from '../../components/Page';
import PhoneIcon from '@web/components/DialerActivity/icons/Phone';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from '@admin/utils/formatPhoneNumber';

const Box = styled.div`
  background-color: ${({ theme }) => theme.colors.whiteSmoke};
  margin: 0 auto 30px;
  max-width: 230px;
  padding: 18px 20px;
  text-align: center;
`;

const TryAgain = styled(BackButton)`
  font-family: ${({ theme }) => theme.fonts.boldItalic};
  height: 25px;
`;

function Dialing({ onBack, onNext }) {
  const { t } = useTranslation();

  const dialerState = useSelector(state => state.dialer);
  const { callData, volunteerPhone } = dialerState;

  return (
    <Page>
      <PhoneIcon size={118} />
      <PageCopy>
        <CopyWrapper>
          <Title>
            {t('activity.phone_bank.were_dialing')} {formatPhoneNumber(volunteerPhone)}
          </Title>
          <Body
            style={{ marginBottom: 20 }}
            dangerouslySetInnerHTML={{
              __html: t('activity.phone_bank.you_will_receive_a_call', {
                phone: formatPhoneNumber(callData?.campaign_number),
              }),
            }}
          />
        </CopyWrapper>
        <Box>
          <Body style={{ marginBottom: 0 }}>{t('activity.phone_bank.call_failed_question')}</Body>
          <TryAgain variant="secondary" onClick={onBack} style={{ margin: '0 auto' }}>
            {t('common.try_again')}
          </TryAgain>
        </Box>
      </PageCopy>
      <NextButton onClick={onNext}>{t('activity.phone_bank.call_answered')}</NextButton>
      <BackButton onClick={onBack}>{t('common.cancel')}</BackButton>
    </Page>
  );
}

Dialing.propTypes = {
  dialerState: PropTypes.shape({
    callData: PropTypes.shape({
      campaign_number: PropTypes.string,
    }),
    phoneNumber: PropTypes.string.isRequired,
  }),
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

Dialing.defaultProps = {};

export default Dialing;
